.instagram {
    margin-bottom: 30px;

    .ig-container {
  	margin: 0;
  	padding: 3px 0;
  	list-style: none;
  	position: relative;
  	width: 100%;
    background-color: $color-white;
    display: inline-block;

        a,
        img {
            display: block;
            width: 100%;
        }

        &__intro,
        &__other-social {
            position: relative;

            .intro-box-overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                .other-container {
                    position: relative;
                    margin-top: 30%;

                    .other-text {
                        color: $color-white;
                        text-align: center;
                        font-size: rem-calc(12);
                        font-weight: 500;
                        margin: 0 0 8px 0;

                        @include breakpoint(medium) {
                            font-size: rem-calc(18);
                            margin: 0 0 15px 0;
                        }

                    }

                    .other-socialmedia {
                        text-align: center;

                        a {
                          display: inline;

                            i {
                                color: $color-white;
                                font-size: rem-calc(32);
                                margin: -5px;

                                @include breakpoint(medium) {
                                    font-size: rem-calc(38);
                                    margin: 0;
                                }

                                @include breakpoint(large) {
                                    font-size: rem-calc(49);
                                }

                            }

                        }

                    }

                }

                .intro-container {
                    position: relative;
                    transform: translate(0%, 65%);

                    @include breakpoint(medium) {
                        transform: translate(0%, 100%);
                    }

                    @include breakpoint(large) {
                        transform: translate(0%, 120%);
                    }

                    .intro-text {
                        font-size: rem-calc(18);
                        color: $color-almost-black;
                        text-align: center;
                        font-weight: 500;
                        margin-bottom: 20px;

                        @include breakpoint(medium) {
                            font-size: rem-calc(24);
                        }

                    }

                    .ig-logo {
                        max-width: 60%;
                        margin: 0 auto;
                    }

                }

            }

        }

        &__item {
        		width: calc(100% / 2);
            position: relative;
            float: left;
            overflow: hidden;
            padding: 3px;
            height: 200px;

            @include breakpoint(medium) {
              width: calc(100% / 3);
              height: 260px;
            }

            @include breakpoint(large) {
              width: calc(100% / 4);
              height: 320px;
            }

            @include breakpoint(xxxlarge) {
              width: calc(100% / 6);
              height: 380px;
            }

            .ig-image {
                max-width: 100%;
            }

            .ig-overlay {
                margin: 4px;
                padding: 25px;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(64,112,159,0.9);
                display: flex;
                align-items: center;
                text-align: center;
                opacity: 0;
                color: $color-white;

                p {
                    color: $color-white;
                    font-size: rem-calc(16);
                    font-weight: 300;
                }

                &:hover {
                    opacity: 1;
                }

            }

        }

    }

}
