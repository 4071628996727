.logo-niod-small {
    font-family: 'Khand', sans-serif;
    position:fixed;
    display:inline-block;
    top:0px;
    left:0;
    color:#fff;
    font-size: rem-calc(20);
    padding:12px 16px;
    z-index:1000;
    @include breakpoint(large) {
        font-size: rem-calc(24);
        padding:14px 16px;
    }
}


.homepage .topbar .logo-niod-web {
    display:none;
}
.topbar__mobile-menu .logo-niod-web-b,
.topbar__menu .logo-niod-web-b {
    display:none;
}
.homepage .topbar__menu.desktop-opacity,
.homepage .topbar__mobile-menu.mobile-background {
    .logo-niod-web-b {
        @extend .logo-niod-small;
        display: inline-block !important;
    }
}

.topbar {
    position: relative;
    opacity: 1;

    .logo-niod-web {
        @extend .logo-niod-small;


        @media only screen and (max-width: 680px) {
            background-color: $color-blue;
            padding-top: 10px;
            padding-bottom: 10px;
        }

    }

    .desktop-opacity {
        background-color: $color-blue !important;
        -webkit-transition: 1.7s ease;
        opacity: 1;
    }

    .mobile-background {
        background-color: $color-blue !important;
        -webkit-transition: 1.7s ease;
        width: 100%;
        height: 50px;
    }

    .mobile-extra-border {
        border-left: 2px solid white;
        border-right: 2px solid white;
    }


    &__mobile-menu {
        position: fixed;
        z-index: 2;
        right: 0;
        top: 0;

        .logo-niod-web {
            font-size: rem-calc(18);
            color:#000;
        }

        @include breakpoint(medium) {
            display: none;
        }

        .search-small-container {
            height: 108px;
            width: 100%;
            background-color: $color-blue;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 4;
            display: none;

            fieldset {
                border: none;
                padding: 0;
                position: relative;
                width: 90%;
                margin: 38px auto;

                @media only screen and (max-width: 890px) {
                    width: 90%;
                    margin-top: 50px;
                }

                input {
                    width: 80%;
                    height: 45px;
                    display: inline-block;
                    margin: 0;
                    padding: 10px;
                }

                button {
                    height: 45px;
                    width: 20%;
                    background: $color-light-grey;
                    display: inline-block;
                    position: absolute;
                    border: none;
                    color: $color-black;
                    font-size: 0; 

                    &:after{
                        font-family: "fontello";
                        content: '\e801';
                        font-size: rem-calc(24);
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        width: 1em;
                        margin-right: .2em;
                        text-align: center;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1em;
                        margin-left: .2em;
                    }
                }
            }

            .search-close {
                top: 0px;
                right: 0;
                position: absolute;
                width:32px;
                height:32px;
                font-size: rem-calc(42);
                line-height:2rem;
                color: $color-white;
                background:#000;
                border:none;
                text-align:center;
                padding:0;
            }

        }

        .search-select {
            @media only screen and (max-width: 680px) {
                width: 70%;
                display: none;
            }
        }

        .hamburger-menu,
        .english-toggle,
        .search-toggle {
            height: 50px;
            width: 50px;
            background-color: $color-blue;
            position: absolute;
            top: 0;
            z-index: 3;

            .icon-search,
            .english-link {
                color: $color-white;
                display: block;
                text-align: center;
            }

        }

        .english-toggle {
            right: 106px;

            a {
                padding: 12px 0px 0px 7px;
                color: $color-white;
                font-size: rem-calc(20);
                margin: 0;
                display: inline-block;
                font-family: 'Khand', sans-serif;
                font-weight: 500;
                font-size: rem-calc(27);
            }

        }

        .search-toggle {
            right: 53px;

            .icon-search {
                font-size: rem-calc(24);
                padding: 10px 0;
            }

        }

        .hamburger-menu {
            right: 0;

            .hamburger-icon {
                @include hamburger($color-white, $color-white, 25px, 15px, 3px, 3);
                background: none;
                border: none;
                height: 50px;
                width: 50px;
                &::after {
                  top: 12px;
                  left: 10px;
                  width:30px;
                  box-shadow: 0 8px 0 $color-white, 0 16px 0 $color-white;
                }
            }
        }
    }

    &__menu {
        display: none;

        @include breakpoint(medium) {
            display: flex;
            background-color: rgba($color-blue, 0.8);
            justify-content: space-between;
            text-transform: uppercase;
            font-family: 'Khand', sans-serif;
            height: 60px;
            position: fixed;
            width: 100%;
            z-index: 999;
            top: 0;
        }

        .menu-items {
            margin:0 auto;
            height: inherit;

            .menu {
                height: 56px;

                a {
                    font-size: rem-calc(20);
                    color: $color-white;
                    line-height: inherit;
                    outline: 0;

                    @include breakpoint(large) {
                        font-size: rem-calc(24);
                    }

                    &:hover {
                        color: $color-text;
                    }

                }
                .active > a {
                    background: none;
                }
                .current a {
                    background: none;
                    color: $color-text;
                }

                li {
                    height: inherit;
                }

                li.is-dropdown-submenu-parent > a::after {
                    border-color: $color-white transparent transparent;
                }
                li.is-dropdown-submenu-parent > a:hover::after,
                li.current.is-dropdown-submenu-parent > a::after  {
                    border-color: #000 transparent transparent;
                }

            }

            .extra-menu-level {
                top: 60px !important;
                position: fixed;
                width: 100%;
                background: $color-white;
                text-align: center;
                border-bottom: 1px solid $color-blue;
                @include breakpoint(large) {
                    font-size: rem-calc(24);
                }

                li {
                    width: auto;
                    display: inline-block;

                    a {
                        color: $color-text;
                        font-size: rem-calc(21);

                        &:hover {
                            color: $color-blue;
                        }

                    }

                }

            }

        }

        .language {
            align-self: center;
            //flex-basis: 15%;
            border-left: 1px solid rgba(255, 255, 255, .4);
            height: inherit;
            position:absolute;
            right:0;
            top:0;
            a {
                padding: 12px 16px 0;
                //padding:16px 0;
                color: $color-white;
                font-size: rem-calc(20);
                margin: 0;
                display: inline-block;

                @include breakpoint(large) {
                    font-size: rem-calc(24);
                    padding:16px 16px;
                }

                &:hover {
                    color: $color-text;
                }

            }

        }

    }

    &__background {
        position: relative;
        height: 200px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;

        @include breakpoint(medium) {
            position: relative;
            height: 100vh;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
        }

        .logo {
            width: 90px;
            height: 90px;
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: $color-blue;
            border-radius: 50%;
            cursor: default;
            z-index: 1;
            &:hover {
                background-color: lighten($color-blue, 5%);
            }

            @include breakpoint(medium) {
                width: 180px;
                height: 180px;
                top: 100px;
                left: 100px;
            }

            .link-overlay {
              width: 90px;
              height: 90px;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 50%;
              @include breakpoint(medium) {
                  width: 180px;
                  height: 180px;
              }
            }

            .logo-container {
                position: relative;

                span {
                  color: $color-white;
                  position: absolute;
                  letter-spacing: 1px;
                  font-family: 'Khand', sans-serif;
                }

                .beeldbank {
                    top: 16px;
                    left: 12px;
                    text-transform: uppercase;
                    font-size: rem-calc(15);
                    @include breakpoint(medium) {
                        top: 35px;
                        left: 24px;
                        font-size: rem-calc(30);
                    }
                }

                .wo {
                    top: 21px;
                    left: 12px;
                    text-transform: uppercase;
                    font-size: rem-calc(47.5);
                    @include breakpoint(medium) {
                        top: 42px;
                        left: 24px;
                        font-size: rem-calc(95);
                    }
                }

                .two {
                    top: 28.5px;
                    left: 64px;
                    font-size: rem-calc(28);
                    @include breakpoint(medium) {
                        top: 57px;
                        left: 128px;
                        font-size: rem-calc(56);
                    }
                }

                .nl {
                    top: 57px;
                    left: 64px;
                    font-size: rem-calc(12);
                    @include breakpoint(medium) {
                        top: 114px;
                        left: 128px;
                        font-size: rem-calc(24);
                    }
                }
            }
        }

        .search {
            position: absolute;
            top: calc(100vh - 20%);
            left: calc(50% - 408px);
            width: 835px;
            margin: auto;
            display: block;
            font-size: rem-calc(18);

            @media only screen and (max-width: 890px) {
                width: calc(90% - 20px);
                left: 20px;
            }

            @media only screen and (max-width: 680px) {
                top: 60%; 
            }

            @media only screen and (max-width: 640px) {
                display: none;
            }

            form {
                
                display: block;

                .search-blocks {
                    //box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.5);
                    height: 66px;
                    border: none;
                }

                .search-input {
                    width: 500px;
                    padding-left: 15px;
                    display: inline-block;
               

                    @media only screen and (max-width: 890px) {
                        width: 40%;
                        left: 0;
                    }

                    @media only screen and (max-width: 680px) {
                        width: 100%;
                        left: 0;
                        display: block;
                    }
                }

                .search-select {
                    width: 180px;
                    -webkit-appearance: button;
                    -webkit-border-radius: 0;
                    border-radius: 0;
                    padding-left: 15px;
                    display: inline-block;
                    
                    @media only screen and (max-width: 680px) {
                        width: 70%;       
                    }

                }

                .search-button {
                    width: 136px;
                    background-color: $color-blue;
                    color: $color-white;
                    padding: 0;
                    font-weight:500;
                    display: inline-block;

                    &:hover {
                        background-color:lighten( $color-blue, 10% );
                    }

                    @media only screen and (max-width: 680px) {
                        width: 0%;       
                    }

                }
            }
        }

        .scroll-down {
            display: none;

            @include breakpoint(medium) {
                position: absolute;
                bottom: 0;
                left: calc(50% - 45px);
                width: 90px;
                height: 45px;
                border-top-left-radius: 90px;
                border-top-right-radius: 90px;
                background-color: $color-light-grey;
                display: block;
            }

            a {
                text-align: center;
                display: block;
                font-size: 21px;
            }

        }

    }

}

.com_contact{
    .contact .container {
        margin-top: 0px;
    }

    .content-container{
        padding: 40px 100px 30px !important;
        background: #ffffff;
    }
}