.more-links-block {
    width: 100%;
    height: auto;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 30px 0;
    padding: 40px 0;
    flex-direction: column;

    @include breakpoint(medium) {
        flex-direction: row;
    }

    .block-title {
        width: 100%;
        padding: 10px;
        position: relative;

        @include breakpoint(medium) {
            width: 50%;
            align-self: center;
        }


        i {
          font-size: rem-calc(13);
        }

        .links {
            margin: 10px 0;

            p {
                margin: 10px 0;
                text-align: center;
                font-weight: 300;
                font-size: rem-calc(21);
                @include breakpoint(medium) {
                    font-size: rem-calc(28);
                }
            }


        }

    }

}
