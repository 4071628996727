.beheerd-door {
    width: 100%;
    height: auto;
    background: $color-white;

    p {
        margin: 10px 0;
        text-align: center;
        font-weight: 300;
        font-size: rem-calc(21);
        padding: 20px;
        @include breakpoint(medium) {
            font-size: rem-calc(28);
        }

    }

}

.blog .page-header h2 {
  margin: 0 !important;
}
