.error-left,
.error-right {
  background-color: transparent;
  margin: 0;
  padding: 0;
  @include breakpoint(medium) {
    margin: 50px 0;
    padding: 50px;
    background-color: rgba($color-white, 0.8);
  }
}

.error-left {
  margin-top: 200px;
  padding: 15px;
  @include breakpoint(medium) {
    margin: 50px 0;
  }
  h1 {
    font-size: 78px;
    font-size: remc-calc(40);
    font-weight: bold;
  }

  .intro {
    font-size: 26px;
    margin: 20px 0;
    font-weight: bold;
    color: $color-text;
  }

  p {
    margin-bottom: 20px;
    line-height: 28px;
  }
}

.error-right {
  position: inherit;
  @include breakpoint(medium) {
    position: relative;
  }
  .logo {
    @include breakpoint(medium) {
      top: 80px;
      left: 155px;
    }
  }
}
