.scroll-container {
    position: relative;
    .scroll-help {
        z-index: 1;
        display: none;
        position: absolute;
        right: 27px;
        bottom: -44px;
        transition: transform 0.2s ease-out;
        display: block;
        border: none;

        &__label {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-50%);
            font-size: rem-calc(18);
            line-height: rem-calc(20);
            color: $color-text;
            opacity: 1;
            transition: opacity 0.2s ease-out;
        }

        &__button {
            display: none;
            @include breakpoint(medium) {
              display: block;
              position: relative;
              width: 36px;
              height: 36px;
              text-decoration: none;
              cursor: default;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &__icon {
            @include center();
            transition: all 0.2s ease-out;

            path {
                fill: $color-text;
            }
        }

        &--scrolled {
            transform: translateY(0);

            .scroll-help__label {
                opacity: 0;
            }

            .scroll-help__icon {
                transform: translate(-50%, -50%) rotate(180deg);
            }

            .scroll-help__button {
                cursor: pointer;

                &:hover {
                    .scroll-help__path {
                        fill: $color-blue;
                    }
                }
            }
        }

        &--focus {
            .scroll-help__button {
                animation: scroll-focus ease-out 0.7s infinite;
            }
        }
    }

    @keyframes scroll-focus {
        0% {
            transform: translateY(-4px);
        }
        50% {
            transform: translateY(4px);
        }
        100% {
            transform: translateY(-4px);
        }
    }

}
