.attendees {
    height: 250px;
    margin: 20px 0;
    padding: 0 20px;
    border-bottom: 5px solid #aaaaaa;
    text-decoration: none;
    background: #ffffff;
    overflow: hidden;
    transition: all 0.5s ease; 

    &:hover{
        background: $color-blue;
        border-bottom: 8px solid #aaaaaa;
        
        .attendees__image {
            transform: translateY(-200px);
        }

        h4 {
            margin-top: -30%;
            color: #ffffff;
            font-size: 20px;

            @media only screen and (max-width: 1024px) {
                margin-top: -15% !important;
            }
        }
    }

    a{
        height: 250px;
        padding: 20px;
        display: block; 
        text-decoration: none !important;
    }

    &__image {
        width: 200px;
        height: 100px;
        margin: 0 auto;
        display: block;
        background: no-repeat center center; 
        background-size: contain;
        margin-bottom: 10px; 
        transition: all 0.5s ease;
    }

    h4{
        color: $color-dark-blue;
        text-decoration: none;
        text-align: center;
        margin: 10px 0;
        transition: all 0.2s ease;
        font-size: 18px;
    }

}

.attendees-holder {
    margin: 20px 0;

    .columns:last-child:not(:first-child) {
        float: left !important; // to force the element float to the left
    }
}

.thema-item {
    display: block;
}

.category{
    .content-container {
        background: #ffffff;
        padding: 0 100px 20px;


        @media only screen and (max-width: 640px) {
            padding: 0 20px 20px;
        }
    }    
}

