.off-canvas {
    background-color: $color-blue;

    .close-button {
        outline: 0;
        color: $color-white;
        background:#000;
        border:none;
        text-align:center;
        padding:0;
        width:32px;
        height:32px;
        span {
            font-size: rem-calc(38);
            line-height:2rem;
        }
    }

    #topbar {
        margin: 0;

        li a {
            font-size: rem-calc(20);
            color: $color-white;
            &:hover,
            &:focus {
               color: $color-text;
               outline: 0;
            }
        }

        .active > a {
            background: $color-white;
            color: $color-text;
        }

        .extra-menu-level {
            position: absolute;
            top: 40px;
            left: 0;
            z-index: 0;
            display: none;
            min-width: 200px;
            border: 0;
            background: transparent !important;
        }

    }

}
