.site-footer {
    height: auto;
    background: $color-blue;
    padding: 30px;
    text-align: center;
    width: 100%;
    p {
        color: $color-white;
        display: inline-block;
        padding-right: 20px;
        font-size: rem-calc(17);
        margin: 0;
    }
    a {
        color: $color-white;
        text-decoration: underline;
        &:hover {
            color: $color-text;
        }
    }
}
