// apply a natural box layout model to all elements, but allowing components to change
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    min-height: 100%;
    padding: 0;
    margin: 0;
    background-color: $color-light-grey;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

// Reset
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
    margin:0;
    padding:0;
}
