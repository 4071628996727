

table{

    tbody{

        tr{
            border-bottom: 1px solid $color-lightest-grey;
            td{

                p{
                    margin: 0;
                }
    
                &:last-child{
                    border-left: 1px solid $color-lightest-grey;
                }
            }
        }
    }
}

