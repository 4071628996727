.com_mediabank {
    background-color: $color-white;
    .site-footer {
        display: none;
    }

    .leaflet-container .leaflet-control-attribution, .leaflet-container .leaflet-control-scale {
        display: none;
    }
    .overlayPlayContainer .iconButton {
        border: none !important;
    }
}

.mediabank-detail-view .mediabank-topviewer {
    top: 170px;
}

.mediabank-search .angucomplete-dropdown {
    margin-top: 45px;
    box-shadow: none !important;
}

.mediabank-detail-view .mediabank-detail-side {
    top: 170px;
}

.mediabank-filters {
    margin: 10px;
}

#download_popup {
    p {
        padding: 10px 0;
    }
    a {
        color: $color-blue;
    }
    .download_link {
        padding: 10px;
        text-align: center;
        display: block;
        background: $color-blue;
        color: $color-white;
    }
    .close-button {
        background: none;
        border: none;
        color: $color-black;
    }
}

table thead, table tbody, table tfoot {
    border: none !important;
    background-color: transparent !important;
}

.mediabank-header {
    margin-top: 90px;
    background: $color-blue;
    color: white;
    @media only screen and (max-width: 799px) {
        margin-top: 50px;
    }
    .mediabank-intro {
        padding: 21px 0;
    }
}

.comment-success {
    background: none;
    padding-left: 0; 
    
}

.pic-load-previous {
    background: $color-blue !important;
}

.mediabank-filter-icon {
    top: -1px !important;
}

.pic-mediabank {
    background-color: $color-white;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 300;
    .mediabank-gallery-tooltip {
        @media only screen and (max-width: 414px) {
            display: none;
        }

        .tooltip-content {
            border: 2px solid $color-black;

            &::after {
                border-top-color: $color-black;
            }

        }

    }

    .filter-active {
        background-color: $color-blue;
    }

    slider div.bar .selection, [slider] div.bar .selection {
        background-color: $color-blue;
    }

    .mediabank-filter-dropdown {
        background-color: $color-blue;
        input[type=number] {
            color: $color-black;
        }

        a {
            color: $color-white !important;
        }
    }

    .mediabank-filter-dropdown .mediabank-page-prev, .mediabank-filter-dropdown .mediabank-page-next {
        background-color: $color-blue;
    }

    .mediabank-result-header {
        background: none;
        float: left;
        margin-bottom: 0;
        padding: 0;
    }

    .mediabank-search {
        position: relative;
        height: 170px;
        padding: 65px 0;
        margin: 0;
        z-index: 1;

        &:after {
            position: absolute;
            background-image: url(/images/mediabank-background/sml01.jpg);
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            left: 50%;
            top: 0;
            width: 100vw;
            z-index: 1;
            height: 170px;
            display: block;
            content: '';
            transform: translateX(-50%);
        }

        form {
            position: relative;
            z-index: 2;
        }

        input[type=text] {
            position: absolute;
            border-radius: 0;
            border: none;
            height: 35px;
            width: 100%;
            left: 45px;
            top: 0;
            margin-bottom: 0;
            padding: 0 10px;
            font-size: rem-calc(14);

            @include breakpoint(medium) {
                height: 50px;
                left: 60px;
                font-size: rem-calc(16);
            }

        }

        button {
            border-radius: 0;
            background-color: $color-white;
        }

        .mediabank-button-back {
            background-color: $color-blue;
            &:hover  {
                background-color: $color-dark-blue;
            }
        }

        .mediabank-search-submit {
            height: 35px;
            position: absolute;
            border-radius: 0px;
            font-size: 1.2em;
            top: 0;
            left: 15px;
            width: 30px;
            padding: 0;

            &:hover {
                background: $color-blue;
            }

            @include breakpoint(medium) {
                height: 50px;
                width: 60px;
                left: 0;
            }

            i {
                display: none;
            }

            &:before {
                font-family: "fontello";
                position: relative;
                content: '\e801';
                top: 0;
                left: 3px;
                color: $color-black;
                font-size: rem-calc(21);

                @include breakpoint(medium) {
                    font-size: rem-calc(28);
                    left: 0;
                }

            }

            span {
                display: none;
            }

        }
    }

    .mediabank-filter-list .mediabank-filter:hover {
        color: $color-blue;
    }

    .mediabank-filter-list>li.active {
        color: $color-blue;
    }

    .mediabank-filter-list {
        @include breakpoint(medium) {
            padding-left: 0;
        }
    }

    .mediabank-result-header .mediabank-results-total {
        margin-left: 10px;
        .total {
            font-weight:500;
        }
    }

    .mediabank-result-options {
        margin: 20px 10px;
    }

    .mediabank-result-options, .mediabank-gallery-mode .active {
        border-color: $color-dark-grey;
    }

    .mediabank-gallery-mode {
        li {
            height:43px;
            background:none;
            &.active {
                background:#fff;
                a {
                    color: $color-blue;
                }
            }
            &:hover a  {
                color: $color-blue;
            }
        }
        a {
            color: gray;
        }
    }

    .mediabank-gallery-item.focused::after {
        border-bottom-color: black;
    }

    .mediabank-gallery-focused-thumb {
        background-color: black;
    }

    .mediabank-gallery-thumb {
        background-color: gray;
    }

    .mediabank-thumb-pagination a {
        background-color: $color-blue;
        color: white;
    }

    .mediabank-gallery .mediabank-gallery-thumb .spinner-wrapper {
        background-color: gray;
    }

    .spinner-wrapper .double-bounce1, .spinner-wrapper .double-bounce2 {
        background-color: $color-blue;
    }

    .pagination-button-prev, .pagination-button-next {
        background-color: $color-blue;

        &:hover {
            background-color: $color-dark-blue;
        }
    }

    .mediabank-detail-panel-button {
        color: white;
    }

    .mediabank-detail-menu {
        background-color: $color-blue;
    }

    .mediabank-detail-menu li {

        &:hover a, &.active a {
            color: $color-black;
        }

    }
    //DETAILPAGE
    .openseadragon-container {
        background: black !important;
    }

    .mediabank-search .mediabank-search-fieldset {
        padding-right: 60px;
        width: 80%;

        @include breakpoint(medium) {
            width: 50%;
        }

    }

    .comment-date,
    .comment-content{
        font-weight: 400;
    }

    .mediabank-detail-paginator {
        z-index: 3;
    }

    .mediabank-detail-panels {
        h2, h3 {
            margin-bottom: 10px;
        }

        input[type=text], textarea {
            border-color: gray;
            font-weight: 400;
        }

        .mediabank-comment-overview h3 {
            background: black;
            color: white;
        }

        .mediabank-comment-button {
            background: $color-blue;
        }
        .mediabank-comment-form {
            &:before {
                content: "Uw reactie wordt pas zichtbaar na controle en redactie.";
                margin-bottom: 5px;
            }
        }
        .mediabank-detail-permalink .mediabank-permalink-copy {
            background: $color-blue;
        }

        li.bijschrift_kort {
            display: none;
        }
        li.annotatie_kort {
            display: none;
        }
        li.download_text {
            display: none;
        }
    }

    .mediabank-gallery-list {
        li.bijschrift_kort {
            display: none;
        }
        li.annotatie_kort {
            display: none;
        }
        li.download_text {
            display: none;
        }
    }

    .mediabank-detail-panel-close {
        background-color: $color-blue;
        color:#fff;
        &:hover  {
             background-color: $color-dark-blue;
         }
    }

    .mediabank-gallery-focused {
        background-color: $color-black;
        color: $color-white;

        .mediabank-focused-close {
            background-color: $color-blue;
        }

        .mediabank-detail-button {
            background-color: $color-blue;
        }
    }

    .mediabank-results-total {
        span {
            font-size: 16px;
        }
        .total {
            font-size: 22px !important;
        }
    }

    .pic-icon-arrow-down {
        font-weight: bold;
    }
    .mediabank-metadata-list .metadata-heading {
        font-weight: 500;
    }



}
