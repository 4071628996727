.thema {
    width: 80%;
    max-width: 1280px;
    height: auto;
    margin: 0 auto;

    h3 {
        font-size: rem-calc(26);
        margin: 25px 0;
        text-align: center;
        @include breakpoint(medium) {
            margin: 15px 0;
            font-size: rem-calc(32);
        }
    }

    .more-stories {
        font-size: rem-calc(18);
        text-align: center;
        font-weight: 300;
        margin: 0 0 40px;
        display: block;

        i {
            font-size: rem-calc(13);
        }
        
    }

    .thema-container {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;

        .thema-item {
            background: $color-white;
            width: 370px;
            height: auto;
            margin: 0 5px 30px 5px;
            text-align: center;
            padding: 8px 8px 0 8px;
            box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.1);

            .thema-image {
              max-width: 100%;
              height: 225px;
              position: relative;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              -webkit-filter: opacity(75%);
              -webkit-transition: all 0.5s;

              &:hover {
                  -webkit-filter: none;
              }

            }

            .thema-title {
              width: 100%;
              height: auto;
              padding: 5px 0;

                a {
                    color: $color-text;
                    font-weight: 300;
                    font-size: rem-calc(15);

                    &:hover {
                        color: $color-blue;
                    }

                    @include breakpoint(medium) {
                        font-size: rem-calc(18);
                    }

                }

            }

        }

    }

}
