$color-gray-black: #333;
$color-text: $color-gray-black;

$color-black: #000;
$color-white: #fff;

$color-almost-black: #262626;
$color-blue: #6FB5E4;
$color-dark-blue: #40709F;
$color-light-grey: #EFEFEB;
$color-dark-grey: #555555;
$color-lightest-grey: #eaeaea;