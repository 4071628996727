body {
    color: $color-text;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 300;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
} 

p, h1, h2, h3 {
    color: $color-text;
}

h1 {
    font-size: rem-calc(36);

    @media only screen and (max-width: 640px) {
        font-size: rem-calc(30);
    }
}

h2 {
    font-size: rem-calc(26);

    @media only screen and (max-width: 640px) {
        font-size: rem-calc(24);
    }
}

h3 {
    font-size: rem-calc(24);

    @media only screen and (max-width: 640px) {
        font-size: rem-calc(22);
    }
}

h4 {
    color: $color-blue;
    font-size: rem-calc(20);
    font-weight: bold;

    @media only screen and (max-width: 640px) {
        font-size: rem-calc(20);
    }
}

a {
    color: $color-dark-blue;
    &:hover {
        color: $color-blue;
    }
}

a, p {
    font-size: rem-calc(18);
    line-height: rem-calc(30);
}

p {
    margin: 0 0 rem-calc(15) 0;
}

ul, ol, dl {
    font-size: rem-calc(18);
    list-style-position: outside;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

ol {
    list-style-position: inside;

    li {
        padding: 10px 0;
    }
}

dl {
    dt,
    dd {
        font-style: normal;
        font-size: rem-calc(18);
    }
}

blockquote{
    margin-top: 10px;
    padding-left: 20px;
    
    p{
        color: #000000;
    }
}

button {
    background: $color-blue;
    color: #ffffff;
    border: 1px solid $color-dark-blue;
}