.webexpo-expositions {
    li {
        background:#fff;
    }
}

#pic-expo {
    margin-top:90px;    
    @include breakpoint(medium) {
        margin-top:0;
    }
}

#picturae-webexpo-container {
    .expositions-list {
        width:100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
            text-align:center;
            margin:0 10px 20px;
            width:calc(100% - 20px);;
            @include breakpoint(medium) {
                width:calc(33.3333% - 20px);
            }
        }
        .webexpo-overview-item {
            max-width:400px;
            margin:0 auto;
        }
    }
}        

.com_webexposition {

    .overview-header h1 {
        font-size: rem-calc(34);
        margin: 0;
    }

    .webexpo-sort {
        top: -2px;
    }

    .button-sort,
    .button-view {
        background-color: $color-blue !important;
        color: #fff !important;
        border-radius: 0 !important;
    }

    .button-view:hover {
        background-color: $color-dark-blue !important;
    }

    .button-fullscreen,
    .button-help,
    .button-return {
        background-color: $color-blue !important;
        border-radius: 0 !important;
        height: 24px;

        &:hover {
            background-color: $color-dark-blue !important;
        }

    }

    .button-fullscreen {
        height: 24px !important;
        width: 25px !important;
    }

    .button-sort {
        font-size: rem-calc(18);
    }

    .animated a:hover {
        text-decoration: none;
        background-color: $color-blue;
    }

}

.pic-webexpo-detail-view {
    background: $color-black;
}
