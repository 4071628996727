.article{

    .niod-content {
            
        .content-container {
            padding: 10px 100px 30px;
            background: #ffffff;

            @media only screen and (max-width: 640px) {
                padding: 10px 24px;
            }

            h1{
                margin-bottom: 20px;
            }

            a{
                text-decoration: underline;
            }

            h2 {
                margin: 5px 0 15px 0;
            }

        }
    }

}

.niod-content {
    // min-height: calc(100vh - 150px);

    @include breakpoint(medium) {
        margin-top: 60px;
    }

    .container {

        .article-backgroundimage {
            height: 235px;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;
        }

    }
}

.contact {

    .container {
        margin-top: -20px;
    }

    button {
        width: 136px;
        background-color: $color-blue;
        color: $color-white;
        padding: 10px;
        border: none;
        margin-bottom: 10px;
    }

    fieldset {
        border: 0;
        padding: 0;
        margin: 30px 0 20px 0;
    }

    legend {
        display: none;
    }

    .controls {

        input,
        textarea {
            padding: 5px;
        }

    }

    .accordion-toggle,
    .jicons-icons,
    .spacer {
        display: none;
    }

    .control-label {
        margin: 5px 0;
    }


    // temp override disable
    .xdebug-error {
        display: none;
    }

    .niod-contact-container {
        width: 100%;
        height: auto;

        .niod-form,
        .niod-address {

        }

    }

}

.breadcrumb {
  margin: 10px -4px 15px;

  li {
    display: inline-block;
    text-decoration: underline;
  }

  .active {
    text-decoration: none;
  }

  span {
    font-size: rem-calc(15);
  }

  .icon-right-open {
    font-size: rem-calc(12);
  }

}
